import React from 'react'
import './App.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const App = () => {
  return (
    <div className="app-container">
      <Carousel
        autoPlay={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        stopOnHover={false}
        interval={8000}
        useKeyboardArrows={true}
        transitionTime={2000}
      >
        <div className="content">
          <span className="content-desc-text">
            We understand that technology is essential to the success of your
            business.
          </span>
        </div>

        <div className="content">
          <span className="content-desc-text">
            Our team of experienced developers works closely with you to craft
            digital solutions that meet your needs and exceed your expectations.
          </span>
        </div>

        <div className="content">
          <span className="content-desc-text">
            We are committed to pushing the boundaries of technology through
            research and development.
          </span>
        </div>

        <div className="content">
          <span className="content-desc-text">
            Our team of experts is dedicated to innovating for tomorrow's
            challenges, uncovering insights, and creating value.
          </span>
        </div>

        <div className="content">
          <span className="content-desc-text">
            We are where ideas come to life, and we are excited to partner with
            you on your next big project.
          </span>
        </div>
      </Carousel>

      <span className="logo">Hextrem</span>
      <div className="footer-cont">
        <div className="cont">
          <div className="left-cont">
            <div className="contents">Facebook</div>
            <div className="contents">Instagram</div>
            <div className="contents">Twitter</div>
          </div>
          <div className="right-cont">
            <div className="contents">Contact Us: +918105272443</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
